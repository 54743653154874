import React from 'react'
import Dynamictable from '../../component/table/Dynamictable'

const EventDetail = () => {
    const tableData = [
        { item1: "Annual Tech Conference", item2: "2024-10-20", item3: "A conference discussing the latest trends in technology." },
        { item1: "Startup Pitch Day", item2: "2024-11-15", item3: "Event for startups to pitch their ideas to investors." },
        { item1: "Web Development Workshop", item2: "2024-12-05", item3: "A hands-on workshop on modern web development practices." },
      ];
      
      const tableHeadings = ["S.No.","Event Title", "Date", "Event Details"];
  return (
    <div>
      <div className="">
      <Dynamictable tableData={tableData} tableHead={tableHeadings} />
    </div>
    </div>
  )
}

export default EventDetail
