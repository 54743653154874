import React, { useState } from 'react';

const BasicDetails = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    age: '',
    phoneNumber: '',
    sendWhatsAppNotification: false,
    note: '',
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Firstname */}
      <div className="mb-4">
        <label htmlFor="firstName" className="form-label">
          Firstname *
        </label>
        <input
          type="text"
          id="firstName"
          name="firstName"
          placeholder="Enter your first name"
          value={formData.firstName}
          onChange={handleChange}
          className="form-input"
          required
        />
      </div>

      {/* Lastname */}
      <div className="mb-4">
        <label htmlFor="lastName" className="form-label">
          Lastname *
        </label>
        <input
          type="text"
          id="lastName"
          name="lastName"
          placeholder="Enter your last name"
          value={formData.lastName}
          onChange={handleChange}
          className="form-input"
          required
        />
      </div>

      {/* Gender */}
      <div className="mb-4">
        <label htmlFor="gender" className="form-label">
          Gender *
        </label>
        <div className="flex space-x-4">
          <label className="flex items-center">
            <input
              type="radio"
              name="gender"
              value="Male"
              checked={formData.gender === 'Male'}
              onChange={handleChange}
              className="form-radio"
            />
            <span className="ml-2">Male</span>
          </label>
          <label className="flex items-center">
            <input
              type="radio"
              name="gender"
              value="Female"
              checked={formData.gender === 'Female'}
              onChange={handleChange}
              className="form-radio"
            />
            <span className="ml-2">Female</span>
          </label>
          <label className="flex items-center">
            <input
              type="radio"
              name="gender"
              value="Transgender"
              checked={formData.gender === 'Transgender'}
              onChange={handleChange}
              className="form-radio"
            />
            <span className="ml-2">Transgender</span>
          </label>
        </div>
      </div>

      {/* Age */}
      <div className="mb-4">
        <label htmlFor="age" className="form-label">
          Age *
        </label>
        <input
          type="number"
          id="age"
          name="age"
          placeholder="Enter your age"
          value={formData.age}
          onChange={handleChange}
          className="form-input"
          required
        />
      </div>

      {/* Phone Number */}
      <div className="mb-4">
        <label htmlFor="phoneNumber" className="form-label">
          Phone Number *
        </label>
        <input
          type="tel"
          id="phoneNumber"
          name="phoneNumber"
          placeholder="Enter your number"
          value={formData.phoneNumber}
          onChange={handleChange}
          className="form-input"
          required
        />
      </div>

      {/* WhatsApp Notification */}
      <div className="mb-4 flex items-center">
        <input
          type="checkbox"
          id="sendWhatsAppNotification"
          name="sendWhatsAppNotification"
          checked={formData.sendWhatsAppNotification}
          onChange={handleChange}
          className=""
        />
        <label htmlFor="sendWhatsAppNotification" className="ml-2 mt-2 form-label">
          Send WhatsApp notification
        </label>
      </div>

      {/* Note */}
      <div className="mb-4">
        <label htmlFor="note" className="form-label">
          Note
        </label>
        <textarea
          id="note"
          name="note"
          placeholder="Enter your note"
          value={formData.note}
          onChange={handleChange}
          className="form-input"
        />
      </div>
      
    </form>
  );
};

export default BasicDetails;
