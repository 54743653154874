import AddBusiness from "../page/bussiness/AddBusiness";
import BusinessDetail from "../page/bussiness/BusinessDetail";
import Bussiness from "../page/bussiness/Bussiness";
import Dashboard from "../page/dashboard/Dashboard";
import HospitalPreview from "../page/hospital/HospitalPreview";
import CategoryDetail from "../page/manageCatagory/CategoryDetail";
import ManageCategory from "../page/manageCatagory/ManageCategory";
import ManageSubCategory from "../page/managesubcatetory/ManageSubCategory";
import ShareHistory from "../page/shareHistory/ShareHistory";
import UserDetail from "../page/users/UserDetail";
import Users from "../page/users/Users";

export const PathRoute = {
  Dashboard: "/",
  Business: "/business",
  AddBusiness: "/business/add",
  BusinessDetail: "/business/detail",
  BusinessDetailLink: "/business/detail/:id",
  //--------------------------------------------------------------
  HospitalPreview: "/detailspreview",
  HospitalPreviewLink: "/detailspreview/:id",
  //-------------------------------------------------------
  Users: "/users",
  UserDetail: "/user/detail",
  UserDetailLink: "/user/detail/:id",



  ShareHistory: "/history",
  //---------------------------------------------------
  ManageCategory: "/category",
  CategoryDetail:"/category/detail",
  CategoryDetailLink:"/category/detail/:id",
  //---------------------------------------------------------
  ManageSubCategory: "/subcategory",
};

export const routeConfig = [
  {
    component: Dashboard,
    path: PathRoute.Dashboard,
  },
  {
    component: HospitalPreview,
    path: PathRoute.HospitalPreview,
  },
  //-----------Bussiness-------------------------------

  {
    component: Bussiness,
    path: PathRoute.Business,
  },
  {
    component: AddBusiness,
    path: PathRoute.AddBusiness,
  },
  {
    component: BusinessDetail,
    path: PathRoute.BusinessDetail,
  },
  {
    component: BusinessDetail,
    path: PathRoute.BusinessDetailLink,
  },
  {
    component: Users,
    path: PathRoute.Users,
  },
  {
    component: UserDetail,
    path: PathRoute.UserDetail,
  },
  {
    component: UserDetail,
    path: PathRoute.UserDetailLink,
  },
  //-----------ShareHistory-------------------------------

  {
    component: ShareHistory,
    path: PathRoute.ShareHistory,
  },
  //-----------ManageCategory-------------------------------

  {
    component: ManageCategory,
    path: PathRoute.ManageCategory,
  },
  {
    component: CategoryDetail,
    path: PathRoute.CategoryDetail,
  },
  {
    component: CategoryDetail,
    path: PathRoute.CategoryDetailLink,
  },
  //-----------ManageSubCategory-------------------------------
  {
    component: ManageSubCategory,
    path: PathRoute.ManageSubCategory,
  },
];
