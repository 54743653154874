import React, { useEffect, useState } from "react";
import Dynamictable from "../../component/table/Dynamictable";
import SearchBar from "../../component/props/SearchBar";
import PageName from "../../component/props/PageName";
import { PathRoute } from "../../router/PathRoute";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { logoutUser } from "../../store/feature/userSlice";

const Bussiness = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLogged } = useSelector((state) => state.user);
  const [allDataRaw, setAllDataRaw] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  // console.log("allDataRaw",allDataRaw);
  const getBusinessDetail = async () => {
    try {
      setLoading(true); // Start loading
      const response = await getAxiosWithToken({
        method: "get",
        url: "Omlar_login_register/businesses_detail_delete/",
      });

      if (response && response.data) {
        setAllDataRaw(response.data);
      } else {
        console.error(
          "Failed to fetch details:",
          response?.data?.msg || "No message available"
        );
      }
    } catch (error) {
      if (error.response?.status === 401) {
        navigate("/");
        await dispatch(logoutUser());
      } else {
        navigate("/404");
      }
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    if (isLogged) {
      getBusinessDetail();
    }
  }, [isLogged]);

  useEffect(() => {
    if (allDataRaw.length > 0) {
      const modifiedData = allDataRaw.map((option) => {
        return {
          item1: option.Business_Name || "NA",
          item2: option.Contact || "NA",
          item3: option.Email || "NA",
          item4: option.Owner || "NA",
          event: "event",
          uniqueKey: option.Business_Id,
          link: PathRoute.BusinessDetailLink,
        };
      });

      setTableData(modifiedData);
    }
  }, [allDataRaw, setTableData]);
  const tableHeadings = [
    "S.N.",
    "Business Name",
    "Contact",
    "Email",
    "Owner",
    "Event",
  ];

  const {params} = useParams();
  console.log("params",params)
  return (
    <div className="md:p-4">
      {/* href={PathRoute.AddBusiness} */}
      <PageName name="Business"  />
      <h1>{params}</h1>
      <SearchBar />

      <Dynamictable
        tableData={tableData}
        tableHead={tableHeadings}
        loading={loading}

      />
    </div>
  );
};

export default Bussiness;
