import React, { useEffect, useState } from "react";
// import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Person4Icon from "@mui/icons-material/Person4";
import Sidebartag from "../props/Sidebartag";
import { PathRoute } from "../../router/PathRoute";
import OmlarLogo from "../../images/OmlarLogo.png";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarHandle } from "../../store/feature/userSlice";
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import CasesOutlinedIcon from '@mui/icons-material/CasesOutlined';
import HourglassTopOutlinedIcon from '@mui/icons-material/HourglassTopOutlined';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined';

const Sidebar = () => {
  const { sidebarHandle  } = useSelector((state) => state.user);

  const location = useLocation(); // Get current location
  const dispatch = useDispatch();

  const handelSidebar = () => {
    dispatch(setSidebarHandle(false))
  };

  const [activeType, setActiveType] = useState(null);


  const handleLinkClick = (pathname) => {
    let pageName = pathname;
    pageName = pageName.split("/");
    if (pageName[1]) {
      pageName = pageName[1];
    } else {
      pageName = "/";
    }
    setActiveType(pageName);
  };

  useEffect(() => {
    handleLinkClick(location.pathname); // Use location.pathname
  }, [location.pathname]); // Depend on location.pathname

  return (
    <>
      <div>
        <div
          onClick={handelSidebar}
          className={` w-64 top-0 z-40 bg-white shadow-md fixed h-screen md:block ${
            sidebarHandle
              ? "md:translate-x-0"
              : " -translate-x-full transition-transform duration-300 delay-300"
          } md:translate-x-0`}
        >
          {/* <div className="p-2 md:hidden text-white"><MenuIcon/></div> */}
          <div className=" text-white">
            <div className=" absolute right-2 top-2 md:hidden ">
              <button className=" z-50 ">
                <CloseIcon />
              </button>
            </div>
            <div className=" bg-gradient-to-tr from-purple-700 to-appBgColor font-semibold py-8 mb-4">
              <div className="flex gap-3 items-center justify-center">
                <img src={OmlarLogo} alt="Logo" className="w-14 ring-2 ring-white rounded-full" />
                <h1 className="text-3xl font-bold text-white">OMLAR</h1>
              </div>
            </div>

            <Sidebartag
              name="Home"
              logo={<AppsOutlinedIcon fontSize="small" />}
              isActive={activeType === "/"}
              path={"/"}
            />
            <Sidebartag
              name="Business"
              logo={<CasesOutlinedIcon fontSize="small" />}
              isActive={activeType === "business"} // Check if current path is active
              path={PathRoute.Business}
            />
            <Sidebartag
              name="User"
              logo={<Person4Icon fontSize="small" />}
              isActive={activeType === "users"}
              // onClick={() => setActiveTag("users")}
              path={PathRoute.Users}
            />
            <Sidebartag
              name="Share History"
              logo={<HourglassTopOutlinedIcon fontSize="small" />}
              isActive={activeType === "history"}
              // onClick={() => setActiveTag("history")}
              path={PathRoute.ShareHistory}
            />
            <Sidebartag
              name="Manage Category"
              logo={<DnsOutlinedIcon fontSize="small" />}
              isActive={activeType === "category"}
              // onClick={() => setActiveTag("category")}
              path={PathRoute.ManageCategory}
            />
            {/* <Sidebartag
              name="Manage Subcategory"
              logo={<DynamicFeedOutlinedIcon fontSize="small" />}
              isActive={activeType === "subcategory"}
              // onClick={() => setActiveTag("subcategory")}
              path={PathRoute.ManageSubCategory}
            /> */}
          </div>{" "}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
