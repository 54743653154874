import React, { useEffect, useState } from "react";
import OmlarLogo from "../../images/OmlarLogo.png";
import banner from "../../images/banner.jpg";
import smartphone from "../../images/smartphone.png";
import whatsapp from "../../images/whatsapp.png";
import gmail from "../../images/gmail.png";
import globe from "../../images/globe.png";
// import logo from "../../images/logo.jpg";
import doctor02 from "../../images/doctor02.png";
// import gallery2 from "../../images/gallery2.png";
import ads from "../../images/ads.png";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import { HospitalHeading } from "../../component/props/DetailHading";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import Person4OutlinedIcon from "@mui/icons-material/Person4Outlined";
import CrisisAlertOutlinedIcon from "@mui/icons-material/CrisisAlertOutlined";
// import CircleIcon from "@mui/icons-material/Circle";
import { DoctorCard } from "../../component/cards/DoctorCard";
import HospitalServiceCard from "../../component/cards/HospitalServiceCard";
import DoctorAtHome from "./hospitalServices/DoctorAtHome";
import PharmacyServicesForm from "./hospitalServices/PharmacyServicesForm";
import TestForm from "./hospitalServices/TestForm";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import MedicationLiquidOutlinedIcon from "@mui/icons-material/MedicationLiquidOutlined";
import MonitorHeartOutlinedIcon from "@mui/icons-material/MonitorHeartOutlined";
import MasksOutlinedIcon from "@mui/icons-material/MasksOutlined";
import MedicalInformationOutlinedIcon from "@mui/icons-material/MedicalInformationOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import AllPartner from "./partner/AllPartner";
import OurFacilities from "./OurFacilities/OurFacilities";
import { useDispatch, useSelector } from "react-redux";
import { getAxiosWithOutToken } from "../../axios/AxiosObj";
import { logoutUser } from "../../store/feature/userSlice";
import { useNavigate } from "react-router";
import AllExpertise from "./expertise/AllExpertise";
import { useParams } from "react-router-dom";

const HospitalPreview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: OmlarId } = useParams();

  // console.log("params", OmlarId);
  //UrlLink- url for image
  const { UrlLink } = useSelector((state) => state.user);
  // Set default background color to purple
  const [bgColor, setBgColor] = useState("#a64686");
  const [doctoratHomeform, setDoctoratHome] = useState(false);
  const [rehabilitationForm, setRehabilitationForm] = useState(false);
  const [pharmacyServicesForm, setPharmacyServicesForm] = useState(false);
  const [patientAttendantServicesForm, setPatientAttendantServicesForm] =
    useState(false);
  const [ICUatHomeForm, setICUatHomeForm] = useState(false);
  const [diagnosticLabTestsForm, setDiagnosticLabTestsForm] = useState(false);
  const [addShow, setAddShow] = useState(true);

  const handeDoctorForm = () => {
    setDoctoratHome(!doctoratHomeform);
  };

  const handleRehabilitationForm = () => {
    setRehabilitationForm(!rehabilitationForm);
  };

  const handlePharmacyServicesForm = () => {
    setPharmacyServicesForm(!pharmacyServicesForm);
  };

  const handlePatientAttendantServicesForm = () => {
    setPatientAttendantServicesForm(!patientAttendantServicesForm);
  };

  const handleICUatHomeForm = () => {
    setICUatHomeForm(!ICUatHomeForm);
  };

  const handleDiagnosticLabTestsForm = () => {
    setDiagnosticLabTestsForm(!diagnosticLabTestsForm);
  };

  // Function to handle color change

  const handleColorChange = (event) => {
    setBgColor(event.target.value); // Update the background color
  };

  const hideAds = () => {
    setAddShow(!addShow);
  };

  //---------------------------get Business Details------------------------------------------------
  const [allDataRaw, setAllDataRaw] = useState([]);
  const [gelleryImg, setGellryImg] = useState([]);
  const [videoUrl, setVideoUrl] = useState([]);
  const [facility, setFacility] = useState([]);
  const [expertise, setExpertise] = useState([]);
  const [doctorData, setDoctordata] = useState([]);
  const [partnerData, setPartnerData] = useState([]);
  const [doctoropd, setDoctoropd] = useState([]);
  const [information, setInformations] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(null);

  useEffect(() => {
    const fetchHospitalData = async () => {
      if (!OmlarId) {
        navigate("/404");
        return;
      }

      try {
        const response = await getAxiosWithOutToken({
          method: "get",
          url: `crud/business/${OmlarId}/data/`,
        });

        if (response.data) {
          setAllDataRaw(response.data);
        } else {
          navigate("/404");
        }
      } catch (error) {
        if (error.response?.status === 401) {
          await dispatch(logoutUser());
          navigate("/");
        } else {
          navigate("/404");
        }
      }
    };

    fetchHospitalData();
  }, [OmlarId, dispatch, navigate]);

  useEffect(() => {
    if (allDataRaw && allDataRaw !== " ") {
      setGellryImg(allDataRaw.galleries);
      setVideoUrl(allDataRaw.videos);
      setFacility(allDataRaw.facilities);
      setExpertise(allDataRaw.expertise);
      setDoctordata(allDataRaw.doctors);
      setPartnerData(allDataRaw.partners);
      setDoctoropd(allDataRaw.doctor_opd);
      setInformations(allDataRaw.informations);
    }
  }, [allDataRaw]);

  useEffect(() => {
    if (allDataRaw && allDataRaw.Color_Scheme) {
      setBgColor(allDataRaw.Color_Scheme);
    } else {
      setBgColor("#a64686");
    }
  }, [allDataRaw]);

  if (!allDataRaw) {
    return <div>Loading...</div>;
  }
  // console.log(
  //   `${`https://api.omlarmulti.com/media/business/` + allDataRaw?.Banner_Image}`
  // );
  const maxImages = 9; // Limit to 9 images
  const galleryImages = gelleryImg?.slice(0, maxImages); // Show only the first 9 images

  // Function to set the current index
  const serialNumber = (indexNum) => {
    if (indexNum >= 0 && indexNum < galleryImages.length) {
      setCurrentIndex(indexNum);
    }
  };

  // Function to go to the next image
  const nextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === galleryImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Function to go to the previous image
  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? galleryImages.length - 1 : prevIndex - 1
    );
  };

  // Display the current image based on the limited array
  const currentImage =
    galleryImages?.length > 0 ? galleryImages[currentIndex] : null;

  return (
    <div className="bg-[#fff9ff]">
      <header
        onChange={handleColorChange}
        className="text-white sm:py-4 sm:px-6 px-2 py-2"
        style={{ backgroundColor: bgColor }}
      >
        <div className="flex items-center justify-between ">
          <div className="flex gap-2 items-start justify-center px-2">
            <img
              src={OmlarLogo}
              alt="Logo"
              className="w-7 ring-1 ring-white rounded-full"
            />
            <div className="text-right">
              <h1 className="md:text-3xl text-2xl font-extrabold text-white">
                OMLAR
              </h1>
              <p className="text-[9px] mt-[-5px]">Stay in touch...</p>
            </div>
          </div>
          <div className="flex gap-3 items-center justify-center">
            <h1 className=" font-bold font-poppins text-white leading-tight">
              Create New <br /> Business ID
            </h1>
          </div>
        </div>
      </header>
      {/* banner--------------------------------------------------- */}
      <div className=" relative w-full">
        <img
          src={`${
            allDataRaw.Banner_Image
              ? `https://api.omlarmulti.com/media/business/` +
                allDataRaw.Banner_Image
              : banner
          }`}
          alt="banner"
          className="w-full sm:h-96 h-40"
        />
        <div className=" absolute -bottom-14 md:left-32  left-6">
          <img
            src={`${
              allDataRaw.Logo
                ? `https://api.omlarmulti.com/media/business/` + allDataRaw.Logo
                : banner
            }`}
            alt="banner"
            className="sm:w-44 sm:h-44 w-28 h-28 rounded-full ring-2 ring-white bg-white"
          />
        </div>
      </div>
      {/* about section--------------------------------------------------- */}
      <div className="bg-gradient-to-t form-red-50 to-white sm:p-6 px-6 ">
        <div className="text-center">
          <h1
            className={`sm:text-3xl text-[25px] pt-4 ml-28 text-right font-extrabold`}
          >
            {allDataRaw?.Business_Name || "Business Name"}
          </h1>
        </div>
        {/* about us--------------------------------------------------- */}

        <div className="my-2">
          <HospitalHeading
            name="About Us:"
            color={bgColor}
            logo={<GroupsOutlinedIcon style={{ fontSize: "40px" }} />}
          />
          <p className=" text-left text-sm leading-snug">
            {allDataRaw?.About || "About Us"}
          </p>
        </div>
        {/* Chairman Message--------------------------------------------------- */}
        <div className="my-2 ">
          <HospitalHeading
            name="Chairman Message:"
            color={bgColor}
            logo={<Person4OutlinedIcon style={{ fontSize: "40px" }} />}
          />
          <div className="flex mt-2">
            {/* Image Section */}
            <div className="text-right flex flex-col items-center">
              <img
                className="sm:w-32 sm:h-32 w-20 h-20"
                src={`${
                  allDataRaw.Chairman_Photo
                    ? `https://api.omlarmulti.com/media/business/` +
                      allDataRaw.Chairman_Photo
                    : doctor02
                }`}
                alt="Chairman_Photo"
              />
              <h3 className="sm:text-xl text-sm">
                {allDataRaw?.Chairman_Name || "Chairman Name"}
              </h3>
              <p className="sm:text-lg text-right text-[9px]">
                {allDataRaw?.Chairman_Qualification || "Chairman Qualification"}
              </p>
            </div>

            {/* Text Section */}
            <div className="w-2/3 ml-2">
              <p className="md:text text-sm leading-tight">
                {allDataRaw?.Chairman_Message || "Chairman Message"}
              </p>
            </div>
          </div>
        </div>
        {/* Our Vision--------------------------------------------------- */}
        <div className="my-4">
          <HospitalHeading
            name="Vision & Mission:"
            color={bgColor}
            logo={<CrisisAlertOutlinedIcon style={{ fontSize: "40px" }} />}
          />
          <p className="text-sm leading-snug">
            {allDataRaw?.Vision_Mission || "Vision Mission"}
          </p>
        </div>
        {/*gallery--------------------------------------------------- */}
        <div className="my-2">
          <h1 className="text-xl font-extrabold text-[#a64686]">
            <FiberManualRecordIcon fontSize="small" />
            Gallery:
          </h1>
          <div
            className="flex gap-2 mb-4 items-center justify-center"
            style={{ col: bgColor }} // Corrected background color syntax
          >
            <div  style={{ color: bgColor }}>
              <PhotoSizeSelectActualOutlinedIcon fontSize="small" />
            </div>
            <h1 className="text-lg font-bold">Our Photos</h1>
          </div>

          <div className="grid grid-cols-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
            {/* slice 0 to 9 for limit the image 9 */}
            {gelleryImg?.slice(0, 9).map((image, index) => (
              <img
                key={index}
                className="w-full sm:h-48 h-20 object-cover border border-[#a64686]"
                src={`${UrlLink}${image?.Image}`} // Correct template literal
                alt={`Gallery ${index + 1}`} // Dynamic alt for each image
                onClick={() => serialNumber(index)}
              />
            ))}
            {/* image zoom preview------------------------------------------------------------------ */}
            <div>
              {gelleryImg && currentIndex !== null && (
                <div
                  className={`fixed top-0 inset-0 h-screen flex items-center justify-center z-50 ${
                    currentIndex !== null ? "bg-black bg-opacity-85" : "hidden"
                  }`}
                >
                  <div className="absolute flex gap-4 top-4 right-4">
                    <div
                      onClick={() => setCurrentIndex(null)}
                      className="text-white hover:bg-white/10 p-2 rounded-full cursor-pointer"
                    >
                      <CloseIcon />
                    </div>
                  </div>
                  {currentImage && currentImage.Image ? (
                    <img
                      src={`${UrlLink}${currentImage.Image}`}
                      // src={`https://api.venturoxtech.com/${currentImage.file}`}
                      alt={currentImage.description || "Image"}
                      className="border-2 shadow-lg transition-transform transform duration-300 ease-in-out scale-100  sm:max-w-screen-sm md:max-w-screen-md max-h-screen lg:max-w-screen-lg"
                    />
                  ) : (
                    "Loading..."
                  )}
                  <div className="absolute w-full bottom-2 flex justify-between  p-4">
                    <button
                      type="button"
                      onClick={prevImage}
                      className="bg-white/70 hover:bg-white text-black px-4 py-4 flex items-start justify-center rounded-full rotate-180"
                    >
                      <KeyboardDoubleArrowRightIcon />
                    </button>
                    <button
                      type="button"
                      onClick={nextImage}
                      className="bg-white/70 hover:bg-white text-black px-4 py-4 flex items-start justify-center rounded-full "
                    >
                      <KeyboardDoubleArrowRightIcon />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/*Our videos--------------------------------------------------- */}
        <div className="my-5">
          <div className="flex gap-2 mb-4  items-center justify-center">
            <div  style={{ color: bgColor }}>
              <VideocamOutlinedIcon />
            </div>{" "}
            <h1 className="text-lg font-bold ">Our Videos</h1>
          </div>
          {/* Gallery Section */}
          <div className="grid grid-cols-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
            {/* Video Items limit - 9 */}
            {videoUrl?.slice(0, 9).map((url, index) => {
              // Extract video ID from the URL
              const videoId = url.Video.split("v=")[1]?.split("&")[0]; // Extract YouTube video ID
              // href="https://www.youtube.com/watch?v=KmYm6zphh_A"
              //video id - KmYm6zphh_A
              //src- https://img.youtube.com/vi/[VIDEO_ID]/hqdefault.jpg
              return (
                <a
                  key={index}
                  href={url.Video}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="w-full sm:h-48 h-20 rounded-2xl object-cover"
                    src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`} // YouTube thumbnail URL
                    alt={`YouTube Thumbnail ${index + 1}`}
                  />
                </a>
              );
            })}
          </div>
        </div>
        {/*Our Facilities--------------------------------------------------- */}
        <div className="my-4">
          <h1 className="text-xl mb-2 font-extrabold "  style={{ color: bgColor }}>
            <FiberManualRecordIcon fontSize="small" />
            Our Facilities :
          </h1>
          <OurFacilities facility={facility} />
        </div>
        {/*Our Empaneled Partner --------------------------------------------------- */}
        <div className="space-y-4">
          <h1 className="text-xl mb-2 font-extrabold "  style={{ color: bgColor }}>
            <FiberManualRecordIcon fontSize="small" />
            Our Empaneled Partner :
          </h1>
          <AllPartner partnerData={partnerData} />
        </div>
        {/*Doctors Panel --------------------------------------------------- */}
        <div className="my-4">
          <div className="flex items-center gap-2">
            <div className=" w-full border-t  p-1"  style={{ borderBlockColor: bgColor }}></div>
            <h1 className="text-2xl w-full text-center mb-2 font-extrabold whitespace-nowrap"  style={{ color: bgColor }}>
              Doctors Panel
            </h1>
            <div className=" w-full border-t  p-1" style={{ borderBlockColor: bgColor }}></div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:px-12">
            {doctorData?.map((data, index) => (
              <DoctorCard
                color={bgColor}
                key={index + "____" + data.id}
                name={data.Doctor_Name}
                drId={data.Id}
                qualification={data.Qualification}
                photo={data.Photo}
                fee={data.Doctor_Fee}
                doctoropd={doctoropd}
              />
            ))}
          </div>
        </div>
        {/*Hospital Services --------------------------------------------------- */}
        <div className="my-4">
          <h1 className="text-3xl mb-2 font-extrabold " style={{ color: bgColor }} >
            Hospital Services
          </h1>
          <h1 className="text-xl font-extrabold" style={{ color: bgColor }}>
            <FiberManualRecordIcon fontSize="small" />
            HOME CARE SERVICES
          </h1>
          {/* Only one API is being used in each service form but the type is different as per the name */}
          <div className="grid  justify-items-center grid-cols-4 gap-2 md:px-12 my-6">
            <div>
              <HospitalServiceCard
                name="Doctor At Home"
                logo={<MedicalInformationOutlinedIcon />}
                border="top"
                handleForm={handeDoctorForm}
                color={bgColor}
              />
              {doctoratHomeform && (
                <DoctorAtHome
                  name="Doctor At Home"
                  handleForm={handeDoctorForm}
                />
              )}
            </div>
            <div>
              <HospitalServiceCard
                name="Rehabilitation & Physiotherapy"
                logo={<MedicationLiquidOutlinedIcon />}
                border="bottom"
                handleForm={handleRehabilitationForm}
                color={bgColor}
              />
              {rehabilitationForm && (
                <DoctorAtHome
                  name="Rehabilitation & Physiotherapy"
                  handleForm={handleRehabilitationForm}
                />
              )}
            </div>
            <div>
              <HospitalServiceCard
                name="Patient Attendant Services"
                logo={<MasksOutlinedIcon />}
                border="top"
                handleForm={handlePatientAttendantServicesForm}
                color={bgColor}
              />
              {patientAttendantServicesForm && (
                <DoctorAtHome
                  name="Patient Attendant Services"
                  handleForm={handlePatientAttendantServicesForm}
                />
              )}
            </div>
            <div>
              <HospitalServiceCard
                name="ICU at Home"
                logo={<MonitorHeartOutlinedIcon />}
                border="bottom"
                handleForm={handleICUatHomeForm}
                color={bgColor}
              />
              {ICUatHomeForm && (
                <DoctorAtHome
                  name="ICU at Home"
                  handleForm={handleICUatHomeForm}
                />
              )}
            </div>
          </div>
          <div className="my-4">
            {/* Links Section */}
            <div className="md:px-12 my-4">
              <div className="flex gap-4 items-center">
                <h3 className={`text-lg font-bold`} style={{ color: bgColor }}>
                  <FiberManualRecordIcon fontSize="small" />
                  PHARMACY SERVICES
                </h3>
                <div className="flex justify-center">
                  <div
                    onClick={handlePharmacyServicesForm}
                    className="text-center text-sm"
                  >
                    <p>Click Here</p>
                    <div className="flex justify-center">
                      <p className="flex  text-center rounded-full text-white  max-w-min " style={{ background: bgColor }}>
                        <ArrowForwardIosOutlinedIcon
                          style={{ fontSize: "15px" }}
                        />
                      </p>
                    </div>
                  </div>
                </div>
                {pharmacyServicesForm && (
                  <PharmacyServicesForm
                    handleForm={handlePharmacyServicesForm}
                  />
                )}
              </div>
              <div className="flex gap-2 mt-5 items-center">
                <h3 className={`text-lg font-bold `} style={{ color: bgColor }}>
                  <FiberManualRecordIcon fontSize="small" />
                  DIAGNOSTICS/LAB TESTS
                </h3>
                <div className="flex justify-center">
                  <div
                    onClick={handleDiagnosticLabTestsForm}
                    className="text-center text-sm"
                  >
                    <p>Click Here</p>
                    <div className="flex justify-center">
                      <p className="flex text-center rounded-full text-white max-w-min " style={{ background: bgColor }}>
                        <ArrowForwardIosOutlinedIcon
                          style={{ fontSize: "15px" }}
                        />
                      </p>
                    </div>
                  </div>
                </div>
                {diagnosticLabTestsForm && (
                  <TestForm handleForm={handleDiagnosticLabTestsForm} />
                )}
              </div>
            </div>
            {/* Information Section */}

            <div className=" md:px-12 mb-4 ">
              <div className="">
                <h1 className="text-xl mb-2 font-extrabold " style={{ color: bgColor }}>
                  <FiberManualRecordIcon fontSize="small" />
                  Information :
                </h1>
                <div className="pl-8 ">
                  {information?.map((info, index) => (
                    <ol
                      key={index}
                      start={index + 1}
                      className="list-decimal space-y-2 text-sm leading-tight mb-2"
                    >
                      <li>{info.Title}</li>
                      <p>{info.Details}</p>
                    </ol>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Our Expertise --------------------------------------------------- */}
        <div className="my-2">
          <h1 className="text-xl mb-2 font-extrabold " style={{ color: bgColor }}>
            <FiberManualRecordIcon fontSize="small" />
            Our Expertise
          </h1>
          <div className="md:px-12">
            <AllExpertise expertise={expertise} />
          </div>
        </div>
        {/*Our blog --------------------------------------------------- */}
        <div className="my-2 ">
          {/* Gallery Heading */}
          <h1 className="text-xl mb-2 font-extrabold " style={{ color: bgColor }}>
            <FiberManualRecordIcon fontSize="small" />
            Our Blog & Social Engagement
          </h1>
          <div className="md:px-12 px-3">
            <div className="w-full h-28 border border-black "></div>
          </div>
        </div>
        {/* Emergency --------------------------------------------------- */}
        <div className="my-4">
          <h1 className="text-xl mb-2 font-extrabold text-red-600 p-2">
            <FiberManualRecordIcon fontSize="small" />
            Emergency Desk :
          </h1>

          {/* Emergency Information Section */}
          <div className="md:px-12 pl-5">
            <div className="">
              <h1 className="text-lg mb-2 font-extrabold">
                &#9679; We are available 24x7
              </h1>
            </div>

            <div className=" ">
              <h1 className="text-lg  font-extrabold py-2">
                &#9679; Hospital Ambulance Contact No.
              </h1>
              <p className="text-lg  font-extrabold text-blue-600 text-center">
                {allDataRaw?.Ambulance_Contact || "Ambulance Contact"}
              </p>
            </div>
            <div className=" ">
              <h1 className="text-lg  font-extrabold  p-2">
                &#9679; Trauma & Accident Care
              </h1>
              <p className="text-lg  font-extrabold text-blue-600 text-center">
                {allDataRaw?.Trauma_Accidental_Care || "Trauma Accidental Care"}
              </p>
            </div>
            <div className=" ">
              <h1 className="text-lg  font-extrabold  p-2">
                &#9679; Emergency Care Dr. Contact No.
              </h1>
              <p className="text-lg  font-extrabold text-blue-600 text-center">
                {allDataRaw?.Emergency_No || "Emergency No."}
              </p>
            </div>
          </div>
        </div>
        <div className="space-y-4">
          {/* Address Section */}
          <div className="">
            <h1 className="text-xl mb-2 font-extrabold " style={{ color: bgColor }}>
              <FiberManualRecordIcon fontSize="small" />
              Address :
            </h1>
            <p className=" w-full pl-5 text-sm">
              {allDataRaw?.Address || "Address"}
            </p>
          </div>

          {/* Map Section */}
          <div className=" px-2 ">
            {/* <h3 className="text-lg font-semibold">Find Us On The Map</h3> */}
            <div className="relative w-full h-32">
              <iframe
                // src={allDataRaw.Google_Map}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.592388042031!2d82.9600448!3d25.4394621!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x398e2dcd9976979b%3A0xbebb88f2a5399efe!2sAshrisha%20IT%20Solutions!5e0!3m2!1sen!2sin!4v1695657121727!5m2!1sen!2sin"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                title="Ashrisha IT Solutions Location"
              ></iframe>
              {/* <div className="mt-2">
                <a
                  href="https://www.google.com/maps/dir/24.9195647,83.7948673/ashrisha+it+solutions/@25.1780436,82.7318979,9z"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline"
                >
                  Click here for directions
                </a>
              </div> */}
            </div>
          </div>
        </div>
        <div className="my-4">
          {/* Contact Us Section */}
          <div className="">
            <h1 className="text-xl mb-2 font-extrabold " style={{ color: bgColor }}>
              <FiberManualRecordIcon fontSize="small" />
              Contact Us :
            </h1>
            <div className=" md:px-12 pl-5 my-2">
              <div className="flex items-center mb-4 gap-2 text-xl text-center font-extrabold my-7">
                <div>
                  <img src={smartphone} alt="" className="w-6 h-6" />
                </div>
                <p> {allDataRaw?.Contact || " NA"}</p>
              </div>
              <div className="flex items-center mb-4 gap-2 text-xl text-center font-extrabold my-7">
                <div>
                  <img src={whatsapp} alt="" className="w-6 h-6" />
                </div>
                <p>{allDataRaw?.WhatsApp_number || " NA"}</p>
              </div>
              <div className="flex items-center mb-4 gap-2 text-xl text-center font-extrabold my-7">
                <div>
                  <img src={gmail} alt="" className="w-6 h-6" />
                </div>
                <p>{allDataRaw?.Email || " NA"}</p>
              </div>
              <div className="flex items-center mb-4 gap-2 text-xl text-center font-extrabold my-7">
                <div>
                  <img src={globe} alt="" className="w-6 h-6" />
                </div>
                <p>{allDataRaw?.Website || "NA"}</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`p-5 ${
            addShow ? "mb-32" : " "
          }  flex items-center justify-center my-5`}
        >
          <p className="text-2xl font-bold text-left text-red-600 font-devanagari">
            अपने व्यापार को बढ़ाएँ,{" "}
            <strong className="text-[30px]">"ओमलर"</strong> ऐप पर आएँ।
          </p>
        </div>
        <div
          className={`fixed bottom-0 right-0 z-40 w-full ${
            addShow ? "" : "hidden "
          }`} style={{ background: bgColor }}
        >
          <div
            onClick={hideAds}
            className="absolute right-0 bg-gray-200 px-2 hover:cursor-pointer"
          >
            <p>close</p>
          </div>
          <img src={ads} alt="ads" className="md:h-16 h-20" />
          <div className="bg-[#fff9ff] py-2 text-center leading-tight font-extrabold">
            Partner of India's growing step <br /> in the digital era...
          </div>
        </div>
      </div>
    </div>
  );
};

export default HospitalPreview;
