import SearchIcon from "@mui/icons-material/Search";
import user from "../../images/user.png";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarHandle } from "../../store/feature/userSlice";
import ProfileDropDown from "../dropDown/ProfileDropDown";
import { useEffect, useRef, useState } from "react";
const Navbar = () => {
  const { sidebarHandle } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const handelSidebar = () => {
    dispatch(setSidebarHandle(!sidebarHandle));
  };
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const profileRef = useRef(null);
  const handleClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (profileRef.current && !profileRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <header className=" fixed top-0 right-0 w-full  z-20  bg-white border-b-2 shadow">
        <div className="flex  items-center justify-between w-full px-3 py-3 shadow-2 md:px-6 2xl:px-11">
          <div className=" flex w-full md:ml-64 items-center justify-between">
            <div className="flex gap-2 ">
              <button
                onClick={handelSidebar}
                className="md:hidden border-2 rounded-full  z-50 "
              >
                {<MenuIcon />}
              </button>
              <form
                action="https://formbold.com/s/unique_form_id"
                method="POST"
              >
                <div className="flex items-center gap-4">
                  <button className="">
                    <SearchIcon />
                  </button>

                  <input
                    type="text"
                    placeholder="Type to search..."
                    className=" bg-transparent text-black focus:outline-none"
                  />
                </div>
              </form>
            </div>
            <div
              onClick={handleClick}
              ref={profileRef}
              className="flex items-center gap-2 border-l-2 md:px-2 pl-1 "
            >
              <div className="text-2xl text-textColor border-textColor border-2 rounded-full cursor-pointer">
                <img
                  src={user}
                  alt="User"
                  className="sm:w-10 sm:h-10 rounded-full"
                />
              </div>
              <h1 className="text-textColor font-semibold px-2 hidden md:block">
                Admin Panal
              </h1>
              {dropdownVisible && (
                <div className=" shadow-md">
                  <ProfileDropDown />
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;
