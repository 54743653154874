import React, { useEffect, useState } from "react";
import DoctorAppointmentForm from "../../page/hospital/DoctorAppointmentForm";
import kaveri from "../../images/kaveri.png";
import { useSelector } from "react-redux";
import doctor02 from "../../images/doctor02.png";

export const DoctorCard = ({
  name,
  qualification,
  photo,
  fee,
  color,
  doctoropd,
  drId,
}) => {
  const [opdtime, setopdtime] = useState();
  const { UrlLink } = useSelector((state) => state.user);
  const [showHandle, setShowHandle] = useState(false);
  const showAppointmentform = () => {
    setShowHandle(!showHandle);
  };

  // console.log("doctorData", doctorData);

  useEffect(() => {
      const result = doctoropd?.find((item) => item.Id === drId);
      setopdtime(result);
  }, [doctoropd]);

  return (
    <div className="w-full">
      {/* Doctor Image */}
      <div className="p-2">
        <div className=" flex items-center justify-between px-2 ">
          <img
            className=" lg:w-24 lg:h-24 w-24 h-24 rounded-full bg-white border"
            src={`${photo ? UrlLink + photo : doctor02}`}
            alt=""
          />
          <div className="text-right ">
            <h2 className={`text-[26px] md:text-2xl font-bold ` }  style={{ color: color }}>
              {name || "Doctor Name"}
            </h2>
            <p className="font-semibold"> {qualification || "Qualification"}</p>
          </div>
        </div>

        <div className="text-right px-2 -mt-3">
          <p className="">OPD Days: {opdtime?.Day?.slice(0, 3).toUpperCase()}
          </p>
          <p className="">OPD Time: {opdtime?.Time}</p>
        </div>

        {/* Appointment Button */}
        <div className="flex justify-end">
          <button
            onClick={showAppointmentform}
            className={` mt-2 bg-[${color}] text-white text-center px-5 py-1 font-bold `} style={{ background: color }}
          >
            Doctor Appointment Form
          </button>
        </div>

        <div>
          {showHandle && (
            <DoctorAppointmentForm drId={drId} name={name} qualification={qualification} photo={photo} fee={fee} doctoropd={opdtime}  showAppointmentform={showAppointmentform} />
          )}
        </div>
      </div>
    </div>
  );
};

export const DoctorFeeCard = () => {
  return (
    <div className=" bg-white shadow rounded-lg overflow-hidden border border-gray-200">
      {/* Doctor Image */}
      <div className="md:p-3 p-1">
        <div className="  items-center justify-around px-2 ">
          <div className=" flex items-center justify-between ">
            <img
              className=" lg:w-24 lg:h-24 w-24 h-24 rounded-full"
              src={kaveri}
              alt="Dr. Kaveri Jain"
            />
            <div className="text-right ">
              <h2 className="text-xl text-gray-600 ">Dr. Kaveri Jain</h2>
              <p className="text-sm font-devanagari"> रोग विशेष</p>
            </div>
          </div>
          <div>
            <div className="flex items-center gap-2 justify-end my-3">
              <h1>Fee- </h1>
              <div
                className={` bg-[#a44484] py-1 px-2 text-white text-center font-semibold  rounded`}
              >
                1500/-
              </div>
            </div>
          </div>
        </div>

        {/* Appointment Button */}
      </div>
    </div>
  );
};
