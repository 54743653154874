import { AddButton } from "../../component/props/Buttons";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const AddDoctor = ({ handleForm }) => {
  
  return (
    <>
      <div className="popup-firstLayer">
        <div className="relative p-4 w-full my-6 mx-auto max-w-3xl">
          <div className="bg-white md:p-5 p-4 rounded-lg shadow-lg">
            <div className="text-right">
              <button onClick={handleForm}>
                <CloseOutlinedIcon />
              </button>
            </div>

            <h2 className="text-2xl font-bold mb-6 text-center">Add Doctor</h2>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="mb-4">
                <label htmlFor="imageUpload" className="form-label">
                  Upload Image *
                </label>
                <input
                  type="file"
                  id="imageUpload"
                  name="imageUpload"
                  accept="image/*"
                  className="form-input w-full border border-gray-300 p-2 rounded"
                  required
                />
              </div>

              <div className="mb-4">
                <label htmlFor="doctorName" className="form-label">
                  Doctor's Name *
                </label>
                <input
                  type="text"
                  id="doctorName"
                  name="doctorName"
                  placeholder="Enter doctor's name"
                  className="form-input w-full border border-gray-300 p-2 rounded"
                  required
                />
              </div>

              <div className="mb-4">
                <label htmlFor="qualification" className="form-label">
                  Qualification *
                </label>
                <input
                  type="text"
                  id="qualification"
                  name="qualification"
                  placeholder="Enter qualification"
                  className="form-input w-full border border-gray-300 p-2 rounded"
                  required
                />
              </div>

              <div className="mb-4">
                <label htmlFor="opdTiming" className="form-label ">
                  OPD Day & Timing *
                </label>
                <input
                  type="text"
                  id="opdTiming"
                  name="opdTiming"
                  placeholder="Enter OPD day & timing"
                  className="form-input w-full border border-gray-300 p-2 rounded"
                  required
                />
              </div>
            </div>

            <div className="flex justify-end mt-6 ">
              <div>
                <AddButton name="Add" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDoctor;
