import React, { useEffect, useState } from "react";
import Dynamictable from "../../component/table/Dynamictable";
import SearchBar from "../../component/props/SearchBar";
import PageName from "../../component/props/PageName";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import {useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { logoutUser } from "../../store/feature/userSlice";
import { PathRoute } from "../../router/PathRoute";


const ManageCategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLogged } = useSelector((state) => state.user);
  const [allDataRaw, setAllDataRaw] = useState([]);
  const [tableData, setTableData] = useState([]);
  const getAllCategory = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: "Omlar_login_register/categories/",
      });

      if (response && response.data) {
        setAllDataRaw(response.data);
      } else {
        console.error(
          "Failed to fetch details:",
          response?.data?.msg || "No message available"
        );
      }
    } catch (error) {
      if (error.response?.status === 401) {
        await dispatch(logoutUser());
        navigate("/");
      } else {
        navigate("/404");
      }
    }
  };

  useEffect(() => {
    if (isLogged) {
      getAllCategory();
    }
  }, [isLogged]);

  useEffect(() => {
    if (allDataRaw.length > 0) {
      const modifiedData = allDataRaw.map((option) => {
        return {
          item1: option.Name,
          uniqueKey: option.Id,
          link: PathRoute.CategoryDetailLink
        };
      });

      setTableData(modifiedData);
    }
  }, [allDataRaw, setTableData]); // Include dependencies

  const tableHeadings = ["S.No", "Category Name", ];
  // "Description", "Actions"

  const tableDatas = [
    {
      sn: 1,
      item1: "Healthcare",
      item2: "Medical and Health Services",
      action: "Action",
    },
    {
      sn: 2,
      item1: "Technology",
      item2: "IT and Software Development",
      action: "Action",
    },
    {
      sn: 3,
      item1: "Education",
      item2: "Schools and Educational Services",
      action: "Action",
    },
    {
      sn: 4,
      item1: "Finance",
      item2: "Banking and Financial Services",
      action: "Action",
    },
    {
      sn: 5,
      item1: "Retail",
      item2: "Retail Stores and Products",
      action: "Action",
    },
  ];
  return (
    <div className="md:p-4">
      <PageName name="Manage Category" href={true} />
      <SearchBar />
      <Dynamictable tableData={tableData} tableHead={tableHeadings} />
    </div>
  );
};

export default ManageCategory;
