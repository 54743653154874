import React, { useEffect, useState } from "react";
import PageName from "../../component/props/PageName";
// import logo from "../../images/logo.jpg";
import MasksOutlinedIcon from "@mui/icons-material/MasksOutlined";
import { DetailHading } from "../../component/props/DetailHading";
import { DoctorCard } from "../../component/cards/DoctorCard";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import QueueOutlinedIcon from "@mui/icons-material/QueueOutlined";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import AddIcCallOutlinedIcon from "@mui/icons-material/AddIcCallOutlined";
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import { Link, useNavigate, useParams } from "react-router-dom";
import { PathRoute } from "../../router/PathRoute";
import smartphone from "../../images/smartphone.png";
import whatsapp from "../../images/whatsapp.png";
import gmail from "../../images/gmail.png";
import globe from "../../images/globe.png";
import AddDoctor from "../hospital/AddDoctor";
import AddEmpaneledPartner from "../hospital/partner/AddEmpaneledPartner";
import AddFacilities from "../hospital/AddFacilities";
import AddExpertise from "../hospital/AddExpertise";
import AddBlog from "../hospital/AddBlog";
import EmergencyDeskForm from "../hospital/EmergencyDeskForm";
// import gallery from "../../images/gallery.jpg";
import AddImageForm from "../hospital/AddImageForm";
import AddVideoForm from "../hospital/AddVideoForm";
import HospitalDetailsForm from "../hospital/HospitalDetailsForm";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useDispatch, useSelector } from "react-redux";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import { logoutUser } from "../../store/feature/userSlice";
import UpdatePartner from "../hospital/partner/UpdatePartner";
import banner from "../../images/banner.jpg";
import EventDetail from "./EventDetail";

const BusinessDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: OmlarId } = useParams();
  // console.log("param OmlarId", OmlarId);

  const { UrlLink } = useSelector((state) => state.user);

  const [docterFromShow, srtDocterFromShow] = useState(false);
  const [emplaneleFromShow, srtEmplanelFromShow] = useState(false);
  const [emplaneleUpdateShow, srtEmplanelUpdateShow] = useState(false);
  const [UpdateId, setUpdateId] = useState("");

  const [facilitiesFromShow, srtFacilitiesFromShow] = useState(false);
  const [expertiseFromShow, setExpertiseFromShow] = useState(false);
  const [blogFromShow, setBlogFromShow] = useState(false);
  const [EmergencyShow, setEmergencyShow] = useState(false);
  const [imageFormShow, setImageFormShow] = useState(false);
  const [videoFormShow, setVideoFormShow] = useState(false);
  const [hospitalDetailsFormShow, setHospitalDetailsFormShow] = useState(false);

  const handledoctoerForm = () => {
    srtDocterFromShow(!docterFromShow);
  };
  const handleEmplaneForm = () => {
    srtEmplanelFromShow(!emplaneleFromShow);
  };
  const handleEmplaneUpdateForm = (Id) => {
    srtEmplanelUpdateShow(!emplaneleUpdateShow);
    setUpdateId(Id);
  };
  const handleFaciliitesForm = () => {
    srtFacilitiesFromShow(!facilitiesFromShow);
  };
  const handleExpertiseForm = () => {
    setExpertiseFromShow(!expertiseFromShow);
  };
  const handleBlogForm = () => {
    setBlogFromShow(!blogFromShow);
  };
  const EmergencyForm = () => {
    setEmergencyShow(!EmergencyShow);
  };
  const ImageForm = () => {
    setImageFormShow(!imageFormShow);
  };
  const videoForm = () => {
    setVideoFormShow(!videoFormShow);
  };
  const HospitalDetailsFormHandle = () => {
    setHospitalDetailsFormShow(!hospitalDetailsFormShow);
  };
  //--------------------Bussiness Details API---------------------------------------------
  const [allDataRaw, setAllDataRaw] = useState([]);
  const [gelleryImg, setGellryImg] = useState([]);
  const [videoUrl, setVideoUrl] = useState([]);
  const [facility, setFacility] = useState([]);
  const [expertise, setExpertise] = useState([]);
  const [doctorData, setDoctordata] = useState([]);
  const [partnerData, setPartnerData] = useState([]);
  const [doctoropd, setDoctoropd] = useState([]);
  const [information, setInformations] = useState([]);

  console.log("gelleryImg", gelleryImg);
  useEffect(() => {
    const fetchHospitalData = async () => {
      if (!OmlarId) {
        navigate("/404");
        return;
      }

      try {
        const response = await getAxiosWithToken({
          method: "get",
          url: `crud/business/${OmlarId}/data/`,
        });

        if (response.data) {
          setAllDataRaw(response.data);
        } else {
          // navigate("/404");
        }
      } catch (error) {
        if (error.response?.status === 401) {
          await dispatch(logoutUser());
          // navigate("/");
        } else {
          // navigate("/404");
        }
      }
    };

    fetchHospitalData();
  }, [OmlarId, dispatch, navigate]);
  useEffect(() => {
    if (allDataRaw && allDataRaw !== " ") {
      setGellryImg(allDataRaw.galleries);
      setVideoUrl(allDataRaw.videos);
      setFacility(allDataRaw.facilities);
      setExpertise(allDataRaw.expertise);
      setDoctordata(allDataRaw.doctors);
      setPartnerData(allDataRaw.partners);
      setDoctoropd(allDataRaw.doctor_opd);
      setInformations(allDataRaw.informations);
    }
  }, [allDataRaw]);
 
  //------------------------------------------------------------------------------------
  const openDetail = (id) => {
    const links = PathRoute.HospitalPreviewLink.replace(":id", id)
    // navigate(links);
    window.open(links, '_blank');

  };
  return (
    <div className="md:p-4">
      <PageName name="Business"/>
      <div onClick={()=>openDetail(OmlarId)} className="flex justify-end text-xl font-semibold">
        <Link>Preview</Link>
      </div>
      {/* Hospital Info Section */}
      <div className="flex justify-center mt-5">
        {/* About Hospital------------------------------------------------------------------ */}
        <div className="w-full shadow-md ">
          {/* Card Header */}
          <div className=" relative w-full">
            <img
              src={`${
                allDataRaw.Banner_Image
                  ? `https://api.omlarmulti.com/media/business/` +
                    allDataRaw.Banner_Image
                  : banner
              }`}
              alt="banner"
              className="w-full  h-72"
            />
            <div className=" absolute -bottom-14 md:left-32  left-6">
              <img
                src={`${
                  allDataRaw.Logo
                    ? `https://api.omlarmulti.com/media/business/` +
                      allDataRaw.Logo
                    : banner
                }`}
                alt="banner"
                className="sm:w-44 sm:h-44 w-28 h-28 rounded-full ring-2 ring-white bg-white"
              />
            </div>
          </div>
          <div className="">
            <div
              onClick={HospitalDetailsFormHandle}
              className=" flex items-end justify-end"
            >
              <h2 className="rounded-full m-2 p-1 cursor-pointer bg-gray-200">
                <EditOutlinedIcon />
              </h2>
            </div>

            <h2 className=" flex justify-center font-bold text-2xl p-4">
              {allDataRaw?.Business_Name || "NA"}
            </h2>
          </div>
          <div className=" md:p-10 p-3 bg-white rounded-lg overflow-hidden">
            <div className="md:mb-12 mb-6">
              <div className="mb-3 text-textColor grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Owner Name */}
                <div>
                  <h5 className="font-bold text-lg">Owner Name:</h5>
                  <p>{allDataRaw?.Chairman_Name || "NA"}</p>
                </div>

                {/* Qualification */}
                <div>
                  <h5 className="font-bold text-lg">Qualification:</h5>
                  <p>{allDataRaw?.Chairman_Qualification || "NA"}</p>
                </div>
              </div>
              <div>
                <h5 className="font-bold text-lg">Chairman Message :</h5>
                <p className="mt-2">{allDataRaw?.Chairman_Message || "NA"}</p>
              </div>
              {/* About Us */}
              <div>
                <h5 className="font-bold text-lg">About Us:</h5>
                <p className="mt-2">{allDataRaw?.About || "NA"}</p>
              </div>
              <div>
                <h5 className="font-bold text-lg">Vision Mission :</h5>
                <p className="mt-2">{allDataRaw?.Vision_Mission || "NA"}</p>
              </div>
              {hospitalDetailsFormShow && (
                <HospitalDetailsForm handleForm={HospitalDetailsFormHandle} />
              )}
            </div>
             {/*Our Blog ---------------------------------------------------- */}
             <div>
              <DetailHading
                name="Event"
                logo={<EditCalendarIcon style={{ fontSize: "40px" }} />}
                // handleForm={handleBlogForm}
              />
              <div className="p-6  space-y-4">
                <EventDetail/>
              </div>
              {blogFromShow && <AddBlog handleForm={handleBlogForm} />}
            </div>
            {/*Our Gallery ---------------------------------------------------- */}
            <div>
              <DetailHading
                name="Gallery"
                logo={<Groups2OutlinedIcon style={{ fontSize: "40px" }} />}
                // handleForm={ImageForm}
              />
              {/*gallery--------------------------------------------------- */}
              <div className="my-2">
                <div className="grid grid-cols-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2">
                  {/* slice 0 to 9 for limit the image 9 */}
                  {gelleryImg?.slice(0, 9).map((image, index) => (
                    <img
                      key={index}
                      className="w-full sm:h-48 h-20 rounded-2xl object-cover border border-[#a64686]"
                      src={`${UrlLink}${image?.Image}`} // Correct template literal
                      alt={`Gallery ${index + 1}`} // Dynamic alt for each image
                    />
                  ))}
                </div>
              </div>
              {imageFormShow && <AddImageForm handleForm={ImageForm} />}
            </div>
            {/*Our Video ---------------------------------------------------- */}
            <div>
              <DetailHading
                name="Video"
                logo={<Groups2OutlinedIcon style={{ fontSize: "40px" }} />}
                // handleForm={videoForm}
              />
              <div className="my-5">
                <div className="grid grid-cols-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2">
                  {/* Video Items limit - 9 */}
                  {videoUrl?.slice(0, 9).map((url, index) => {
                    // Extract video ID from the URL
                    const videoId = url.Video.split("v=")[1]?.split("&")[0]; // Extract YouTube video ID
                    // href="https://www.youtube.com/watch?v=KmYm6zphh_A"
                    //video id - KmYm6zphh_A
                    //src- https://img.youtube.com/vi/[VIDEO_ID]/hqdefault.jpg
                    return (
                      <a
                        key={index}
                        href={url.Video}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="w-full sm:h-48 h-20 rounded-2xl object-cover"
                          src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`} // YouTube thumbnail URL
                          alt={`YouTube Thumbnail ${index + 1}`}
                        />
                      </a>
                    );
                  })}
                </div>
              </div>
              {videoFormShow && <AddVideoForm handleForm={videoForm} />}
            </div>
            {/* Our Facilities---------------------------------------------------- */}
            <div>
              <DetailHading
                name="Our Facilities"
                logo={<QueueOutlinedIcon style={{ fontSize: "40px" }} />}
                // handleForm={handleFaciliitesForm}
              />
              <div className="font-semibold mt-4 mb-8">
                {facility &&
                  facility.map((facility, index) => (
                    <div key={index} className="text-xl flex gap-2 mb-2 ">
                      &#9679; <h1>{facility.Facility}</h1>
                    </div>
                  ))}
              </div>
              {facilitiesFromShow && (
                <AddFacilities handleForm={handleFaciliitesForm} />
              )}
            </div>
            {/* Our Empaneled Partner---------------------------------------------------- */}
            <div>
              <DetailHading
                name="Our Empaneled Partner"
                logo={
                  <CorporateFareOutlinedIcon style={{ fontSize: "40px" }} />
                }
                // handleForm={handleEmplaneForm}
              />
              <div className="p-4 mt-2 mb-8">
                <ul className="list-disc pl-5 space-y-2">
                  {partnerData &&
                    partnerData.map((company, index) => (
                      <li key={index} className=" text-xl font-semibold">
                        {company.Partner_Name}
                      </li>
                    ))}
                </ul>
              </div>
              {emplaneleFromShow && (
                <AddEmpaneledPartner handleForm={handleEmplaneForm} />
              )}
              {emplaneleUpdateShow && (
                <UpdatePartner
                  UpdateId={UpdateId}
                  handleForm={handleEmplaneUpdateForm}
                />
              )}
            </div>
            {/* Doctor Panal---------------------------------------------------- */}
            <div>
              <DetailHading
                name="Doctor Panal"
                logo={<MasksOutlinedIcon style={{ fontSize: "40px" }} />}
                // handleForm={handledoctoerForm}
              />
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 md:px-12">
                {doctorData?.map((data, index) => (
                  <DoctorCard
                    color={"#a64686"}
                    key={index + "____" + data.id}
                    name={data.Doctor_Name}
                    drId={data.Id}
                    qualification={data.Qualification}
                    photo={data.Photo}
                    fee={data.Doctor_Fee}
                    doctoropd={doctoropd}
                  />
                ))}
              </div>
              {docterFromShow && <AddDoctor handleForm={handledoctoerForm} />}
            </div>
            <div className="my-4 ">
              <div className="">
              <DetailHading
                name="Information"
                logo={<ImportContactsIcon style={{ fontSize: "40px" }} />}
                // handleForm={handledoctoerForm}
              />
                <div className="pl-8 my-3 ">
                  {information?.map((info, index) => (
                    <ol
                      key={index}
                      start={index + 1}
                      className="list-decimal space-y-2  leading-tight mb-2"
                    >
                      <li>{info.Title}</li>
                      <p>{info.Details}</p>
                    </ol>
                  ))}
                </div>
              </div>
            </div>

            {/*Our Expertise ---------------------------------------------------- */}
            <div>
              <DetailHading
                name="Our Expertise"
                logo={<Groups2OutlinedIcon style={{ fontSize: "40px" }} />}
                // handleForm={handleExpertiseForm}
              />
              <div className="p-6  space-y-4">
                {expertise &&
                  expertise.map((exprt, index) => (
                    <p
                      key={index}
                      className="pl-5 text-lg italic text-textColor mb-2"
                    >
                      {exprt.Expertise_Name}
                    </p>
                  ))}
              </div>
              {expertiseFromShow && (
                <AddExpertise handleForm={handleExpertiseForm} />
              )}
            </div>

            {/*Our Blog ---------------------------------------------------- */}
            <div>
              <DetailHading
                name="Our Blog"
                logo={<Groups2OutlinedIcon style={{ fontSize: "40px" }} />}
                // handleForm={handleBlogForm}
              />
              <div className="p-6  space-y-4">
                <blockquote className="text-lg italic text-textColor">
                  "-------""
                </blockquote>
              </div>
              {blogFromShow && <AddBlog handleForm={handleBlogForm} />}
            </div>
            
            <div>
              <DetailHading
                name="Contact Us"
                logo={<AddIcCallOutlinedIcon style={{ fontSize: "40px" }} />}
                // handleForm={EmergencyForm}
              />
              <div className="p-6 bg-gray-100">
                <div className="space-y-8">
                  {/* Address Section */}
                  {/* Address Section */}
                  <div className="">
                    <h1 className="text-xl mb-2 font-semibold">
                      {/* <FiberManualRecordIcon fontSize="small" /> */}
                      Address :
                    </h1>
                    <p className=" w-full pl-5 text-sm">
                      {allDataRaw?.Address || "Address"}
                    </p>
                  </div>
                  <div className=" px-2 ">
                    {/* <h3 className="text-lg font-semibold">Find Us On The Map</h3> */}
                    <div className="relative w-full h-32">
                      <iframe
                        // src={allDataRaw.Google_Map}
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.592388042031!2d82.9600448!3d25.4394621!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x398e2dcd9976979b%3A0xbebb88f2a5399efe!2sAshrisha%20IT%20Solutions!5e0!3m2!1sen!2sin!4v1695657121727!5m2!1sen!2sin"
                        width="100%"
                        height="100%"
                        style={{ border: 0 }}
                        allowFullScreen
                        loading="lazy"
                        title="Ashrisha IT Solutions Location"
                      ></iframe>
                      {/* <div className="mt-2">
                <a
                  href="https://www.google.com/maps/dir/24.9195647,83.7948673/ashrisha+it+solutions/@25.1780436,82.7318979,9z"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline"
                >
                  Click here for directions
                </a>
              </div> */}
                    </div>
                  </div>

                  {/* Emergency Desk Section */}
                  <div className="bg-white p-4 rounded-lg shadow-md ">
                    <div className="my-4">
                      <h1 className="text-xl mb-2 font-semibold text-red-600 p-2">
                        Emergency Desk :
                      </h1>
                      <div className="md:px-12 pl-5">
                        <div className="">
                          <h1 className="text-lg mb-2 font-semibold">
                            &#9679; We are available 24x7
                          </h1>
                        </div>

                        <div className=" ">
                          <h1 className="text-lg  font-semibold py-2">
                            &#9679; Hospital Ambulance Contact No.
                          </h1>
                          <p className="text-lg  font-semibold text-blue-600 ">
                            {allDataRaw?.Ambulance_Contact ||
                              "Ambulance Contact"}
                          </p>
                        </div>
                        <div className=" ">
                          <h1 className="text-lg  font-semibold  p-2">
                            &#9679; Trauma & Accident Care
                          </h1>
                          <p className="text-lg  font-semibold text-blue-600 ">
                            {allDataRaw?.Trauma_Accidental_Care ||
                              "Trauma Accidental Care"}
                          </p>
                        </div>
                        <div className=" ">
                          <h1 className="text-lg  font-semibold  p-2">
                            &#9679; Emergency Care Dr. Contact No.
                          </h1>
                          <p className="text-lg  font-semibold text-blue-600 ">
                            {allDataRaw?.Emergency_No || "Emergency No."}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Social Media Section */}
                  <div className="bg-white p-4 rounded-lg shadow-md">
                    <h3 className="text-xl font-semibold mb-2">Contact Us:</h3>
                    <div className=" md:px-12 pl-5 my-2">
              <div className="flex items-center mb-4 gap-2 text-xl text-center  my-7">
                <div>
                  <img src={smartphone} alt="" className="w-6 h-6" />
                </div>
                <p> {allDataRaw?.Contact || " Contact"}</p>
              </div>
              <div className="flex items-center mb-4 gap-2 text-xl text-center  my-7">
                <div>
                  <img src={whatsapp} alt="" className="w-6 h-6" />
                </div>
                <p>{allDataRaw?.Contact || " Contact"}</p>
              </div>
              <div className="flex items-center mb-4 gap-2 text-xl text-center  my-7">
                <div>
                  <img src={gmail} alt="" className="w-6 h-6" />
                </div>
                <p>{allDataRaw?.Email || " Email"}</p>
              </div>
              <div className="flex items-center mb-4 gap-2 text-xl text-center  my-7">
                <div>
                  <img src={globe} alt="" className="w-6 h-6" />
                </div>
                <p>{allDataRaw?.Website || "Website"}</p>
              </div>
            </div>
                  </div>
                </div>
              </div>
              {EmergencyShow && (
                <EmergencyDeskForm handleForm={EmergencyForm} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessDetail;
