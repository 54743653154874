import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

const DateTimeForm = () => {
  const [timeSlot, setTimeSlot] = useState("");

  const timeSlots = ["10:00 am - 2:00 pm", "5:30 pm - 8:40 pm"];

  return (
    <div className="  flex items-center justify-center">
      <div className=" p-8 rounded-lg  w-full max-w-md">
        {/* DatePicker */}
        <div className="mb-4">
          <label className="form-label mb-2 block">Select Date</label>
          <input className="form-input " type="date" />
        </div>

        {/* Time Slot Section */}
        <div className="mb-4">
          <label className="form-label mb-2 block">Time Slot</label>
          <select
            value={timeSlot}
            onChange={(e) => setTimeSlot(e.target.value)}
            className="form-input w-full border-gray-300 rounded-md shadow-sm"
          >
            <option value="">Select Time Slot</option>
            {timeSlots.map((slot, index) => (
              <option key={index} value={slot}>
                {slot}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default DateTimeForm;
