import React from "react";
import DashChart from "../../component/cards/DashChart";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Chart } from "react-google-charts";
import BarsDataset from "../../component/charts/BarCharts";
import OnSeriesItemClick from "../../component/charts/PieChart";
import PieChartWithCustomizedLabel from "../../component/charts/ExpenseChart";

const Dashboard = () => {
  const data = [
    ["Year", "Sales", "Expenses", "Profit"],
    ["2020", 1000, 400, 600],
    ["2021", 1170, 460, 710],
    ["2022", 660, 1120, -460],
    ["2023", 1030, 540, 490],
  ];

  const options = {
    title: "Company Performance",
    hAxis: { title: "Year", titleTextStyle: { color: "#333" } },
    vAxis: { minValue: 0 },
    chartArea: { width: "70%", height: "70%" },
    colors: ["#1b9e77", "#d95f02", "#7570b3"],
    legend: { position: "top" },
    animation: {
      startup: true,
      easing: "linear",
      duration: 1500,
    },
  };
  return (
    <div>
      <div className="grid lg:grid-cols-4 sm:grid-cols-2 gap-4">
        <DashChart
          title="Total Sales"
          icon={<VisibilityOutlinedIcon />}
          total="$3.456K"
          rate="0.43%"
          levelUp
        />
        <DashChart
          title="Total Expenses"
          icon={<VisibilityOutlinedIcon />}
          total="$3.456K"
          rate="0.43%"
          levelUp
        />
        <DashChart
          title="Total Profit"
          icon={<VisibilityOutlinedIcon />}
          total="$3.456K"
          rate="0.43%"
          levelUp
        />
        <DashChart
          title="Growth Rate"
          icon={<VisibilityOutlinedIcon />}
          total="$3.456K"
          rate="0.43%"
          levelUp
        />
      </div>
      <div className="grid grid-cols-6  gap-4 my-4 ">
        <div className="p-4 w-full md:col-span-4 col-span-6 shadow-md bg-white rounded-borderRedius">
          <BarsDataset />
        </div>
        <div className="p-4 flex items-center justify-center md:col-span-2 col-span-6 shadow-md bg-white rounded-borderRedius">
          <PieChartWithCustomizedLabel/>
        </div>
        <div className="flex items-center p-4 w-full md:col-span-2 col-span-6 shadow-md bg-white rounded-borderRedius">
          <OnSeriesItemClick/>
        </div>
        <div className="p-4 w-full md:col-span-4 col-span-6 shadow-md bg-white rounded-borderRedius">
        <Chart
          chartType="AreaChart"
          width="100%"
          height="400px"
          data={data}
          options={options}
        />
      </div>
      </div>
     
    </div>
  );
};

export default Dashboard;
