
const OurFacilities = ({facility}) => {
 
  // const facilitiesArray = [
  //   "General Surgery",
  //   "General Surgery",
  //   "IVF/Test Tube Baby",
  //   "IVF/Test Tube Baby",
  //   "ICU",
  //   "ICU",
  //   "NICU",
  //   "NICU",
  //   "ICU",
  //   "ICU",
  //   "NICU",
  //   "NICU",
  // ];
  return (
    <div>
      <div className="space-y-4">
        <div className="grid grid-cols-1  gap-4 md:px-7 pl-6">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-2  md:px-12">
            {facility &&
              facility.map((facility, index) => (
              <div key={index} className="text-xl flex gap-2 font-extrabold">
                &#9679; <h1>{facility.Facility}</h1>
              </div>
            ))}
          </div>
       
        </div>
      </div>
    </div>
  );
};

export default OurFacilities;
