import React, { useEffect, useState } from "react";
import Dynamictable from "../../component/table/Dynamictable";
import SearchBar from "../../component/props/SearchBar";
import PageName from "../../component/props/PageName";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import { logoutUser } from "../../store/feature/userSlice";
import { PathRoute } from "../../router/PathRoute";
import VisibilityIcon from "@mui/icons-material/Visibility";

const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLogged,activeDeactive } = useSelector((state) => state.user);
  const [allDataRaw, setAllDataRaw] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getBusinessDetail = async () => {
    try {
      setLoading(true);
      const response = await getAxiosWithToken({
        method: "get",
        url: "Omlar_login_register/users_details_Active_Deactive/",
      });

      if (response && response.data) {
        setAllDataRaw(response.data);
      } else {
        console.error(
          "Failed to fetch details:",
          response?.data?.msg || "No message available"
        );
      }
    } catch (error) {
      if (error.response?.status === 401) {
        navigate("/");
        await dispatch(logoutUser());
      } else {
        navigate("/404");
      }
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    if (isLogged) {
      getBusinessDetail();
    }
  }, [isLogged,activeDeactive]);

  useEffect(() => {
    if (allDataRaw.length > 0) {
      const modifiedData = allDataRaw.map((option) => {
        return {
          item1: option.Name || "NA",
          item2: option.Mobile_No || "NA",
          item3: option.Dob || "NA",
          is_active: option.is_active,
          BusinessId: option.Business_Id,
          eyeBtn: <VisibilityIcon />,
          uniqueKey: option.Id,
          link: PathRoute.UserDetailLink,
          businessLink: PathRoute.BusinessDetailLink,
        };
      });
      setTableData(modifiedData);
    }
  }, [allDataRaw, setTableData]);

  const tableHeadings = [
    "S.No",
    "Name",
    "DOB",
    "Mobile No",
    "Business",
    "Actions",
  ];

  return (
    <div className="md:p-4">
      {/* href={true} AddName="Add User" */}
      <PageName name="User" />
      <SearchBar />
      <Dynamictable
        tableData={tableData}
        tableHead={tableHeadings}
        loading={loading}
      />
    </div>
  );
};

export default Users;
