

const AllPartner = ({partnerData}) => {

  
  return (
    <div>
      <div className="space-y-4">
        {/* <div className="grid grid-cols-1  gap-4 md:px-7 ">
          <ul className=" pl-5">
            {healthInsuranceArray &&
              healthInsuranceArray.map((company, index) => (
                <li key={index} className=" text-xl font-semibold">
                 - {company}
                </li>
              ))}
          </ul>
        </div> */}
        <div className="grid grid-cols-1  gap-4 md:px-7">
          <ul className=" pl-5">
            {partnerData &&
              partnerData.map((company, index) => (
                <li key={index} className=" text-xl font-semibold">
                 - {company.Partner_Name}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AllPartner;
