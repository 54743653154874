import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { AddButton } from "../../component/props/Buttons";

const HospitalDetailsForm = ({ handleForm }) => {
  return (
    <>
      <div className="popup-firstLayer">
        <div className="relative p-4 top-52 w-full my-6 mx-auto max-w-3xl">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="text-right">
              <button onClick={handleForm}>
                <CloseOutlinedIcon />
              </button>
            </div>
            <h2 className="text-2xl font-bold mb-6 text-center">
              Hospital Details
            </h2>

            {/* Hospital Name */}
            <div className="mb-4">
              <label htmlFor="hospitalName" className="form-label ">
                Hospital Name *
              </label>
              <input
                type="text"
                id="hospitalName"
                name="hospitalName"
                placeholder="Enter hospital name"
                className="form-input w-full border border-gray-300 p-2 rounded"
                required
              />
            </div>

            {/* About Us */}
            <div className="mb-4">
              <label htmlFor="aboutUs" className="form-label ">
                About Us *
              </label>
              <textarea
                id="aboutUs"
                name="aboutUs"
                rows="4"
                placeholder="Enter details about the hospital"
                className="form-input w-full border border-gray-300 p-2 rounded"
                required
              />
            </div>

            {/* Chairman Image Upload */}
            <div className="mb-4">
              <label htmlFor="chairmanImage" className="form-label ">
                Chairman Upload Image *
              </label>
              <input
                type="file"
                id="chairmanImage"
                name="chairmanImage"
                accept="image/*"
                className="form-input w-full border border-gray-300 p-2 rounded"
                required
              />
            </div>

            {/* Chairman Message */}
            <div className="mb-4">
              <label htmlFor="chairmanMessage" className="form-label ">
                Chairman Message *
              </label>
              <textarea
                id="chairmanMessage"
                name="chairmanMessage"
                rows="4"
                placeholder="Enter chairman's message"
                className="form-input w-full border border-gray-300 p-2 rounded"
                required
              />
            </div>

            {/* Vision & Mission */}
            <div className="mb-4">
              <label htmlFor="visionMission" className="form-label ">
                Vision & Mission *
              </label>
              <textarea
                id="visionMission"
                name="visionMission"
                rows="4"
                placeholder="Enter the vision & mission of the hospital"
                className="form-input w-full border border-gray-300 p-2 rounded"
                required
              />
            </div>

            {/* Cover Image Upload */}
            <div className="mb-4">
              <label htmlFor="coverImage" className="form-label ">
                Cover Image *
              </label>
              <input
                type="file"
                id="coverImage"
                name="coverImage"
                accept="image/*"
                className="form-input w-full border border-gray-300 p-2 rounded"
                required
              />
            </div>

            {/* Add Button */}
            <div className="flex justify-end mt-6">
              <AddButton name="Add" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HospitalDetailsForm;
