

const AllExpertise = ({expertise}) => {

  return (
    <div>
      <div className="space-y-4">
        <div className="grid grid-cols-1  gap-4 md:px-7">
            {expertise &&
              expertise.map((exprt, index) => (
                <p key={index} className="pl-5 text-sm leading-snug">
                  {exprt.Expertise_Name}
            </p>
              ))}
        </div>
      </div>
    </div>
  );
};

export default AllExpertise;
