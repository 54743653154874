import React from "react";
import OmlarLogo from "../../images/OmlarLogo.png";
import { userLogin } from "../../store/feature/userSlice";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import InputError from "../../component/props/InputError";

const initialValues = {
  username: "",
  password: "",
};

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginSchema = Yup.object({
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
  });

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      let username = values.username;
      let password = values.password;
      const response = await dispatch(userLogin({ username, password }));

      // Check the response and navigate if login is successful
      if (response.payload && response.payload !== false) {
        navigate('/');
        setTimeout(() => {
          // window.location.reload(true); // Uncomment if needed
        }, 1000);
      }
    },
  });

  return (
    <div className="justify-center min-h-screen bg-red-100">
      <div className="absolute bottom-0 right-0 bg-pink-200 w-1/4 h-1/3 rounded-tl-full border-t-8 border-appBgColor"></div>
      <div className="absolute top-0 left-0 bg-pink-200 w-1/4 h-1/3 rounded-br-full border-b-8 border-appBgColor"></div>

      <div className="text-white sm:py-4 sm:px-6 p-2">
        <div className="flex items-center justify-between">
          <div className="flex gap-3 items-center justify-center z-40">
            <img
              src={OmlarLogo}
              alt="Logo"
              className="w-14 ring-2 ring-white rounded-full"
            />
            <h1 className="text-3xl font-bold text-appBgColor">OMLAR</h1>
          </div>
          <div className="flex gap-3 items-center justify-center">
            <h1 className="sm:text-3xl font-bold text-appBgColor">
              Create New Business ID
            </h1>
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center bg-red-100">
        <div className="w-full max-w-md p-6 mt-24 m-2 bg-white rounded-primeryBRedius shadow-md z-20">
          <h2 className="text-2xl font-bold text-center mb-6">Login</h2>

          <form onSubmit={handleSubmit}>
            {/* Username Input */}
            <div className="mb-8">
              <label htmlFor="username" className="block mb-2 text-sm text-gray-600">
                Email or Username
              </label>
              <input
                type="text"
                name="username"  // Updated to match Formik initialValues
                value={values.username}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none"
                placeholder="Enter your email or username"
              />
              {/* Show error message conditionally */}
              {touched.username && errors.username && (
                <InputError error={errors.username} touched={touched.username}  warning={false} />
              )}
            </div>

            {/* Password Input */}
            <div className="mb-8">
              <label htmlFor="password" className="block mb-2 text-sm text-gray-600">
                Password
              </label>
              <input
                type="password"
                name="password" // Updated to match Formik initialValues
                value={values.password}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none"
                placeholder="Enter your password"
              />
              {/* Show error message conditionally */}
              {touched.password && errors.password && (
                <InputError error={errors.password} touched={touched.password} warning={false} />
              )}
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full px-3 py-2 text-white bg-appBgColor rounded-md hover:bg-appBgColor focus:outline-none focus:ring focus:ring-indigo-300"
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
