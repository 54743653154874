// import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { getAxiosWithToken } from '../../axios/AxiosObj';
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from '../../function/utilies';
// import { setDeleteDetail} from "../../store/feature/userSlice";

// import { useNavigate } from 'react-router-dom';
// import { useDispatch } from 'react-redux';

export default function DeleteDialogBox({
  open,
  setOpen,
  data,
  redirect =false,
}) {
  // const navigate = useNavigate();
  // const dispatch = useDispatch();

  const handleClose = () => setOpen(false);

  const handleDeleteApi = async () => {
    console.log("this fuction is calling")
    if (data && data.deleteUrl) {
      const response = await getAxiosWithToken({
        method: 'delete',
        url: data.deleteUrl,
      });

      if (response.data?.success && response.data?.msg) {
        alertResponseMsgSuccess(response.data.msg);
        //if redirect is true page will be nagivage priviuse page, after deletion table will update only 
        // {redirect ? navigate(redirect):(dispatch((true)));
        // }
        setOpen(false);
      } else {
        alertResponseMsgError(response.data?.msg || 'An error occurred');
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>{data?.headerText || 'Confirmation'}</DialogTitle>

      <DialogContent >

        <p className='text-xl'>{`Are you sure you want to delete this ${data?.paraText}?` || 'Are you sure you want to delete this item?'}</p>
        {/* <p className='text-red-500'>{`This action can not be undone.`}</p> */}

      </DialogContent>
      <DialogActions>
        <Button onClick={handleDeleteApi} color="primary">
          Confirm
        </Button>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

//-----------------------------------------------------------------------------------------
