import React from 'react';

const AppointmentSummary = () => {
  return (
    <div className="max-w-md mx-auto text-center rounded-lg p-6">
      <h3 className='text-2xl font-bold mb-4'>Summary</h3>
      <h2 className="text-bold mb-4">Your Appointment Booking Summary</h2>
      
      <div className="mb-4">
        <p >Patient Name:</p>
        <p className="text-lg font-semibold">Dipti Dixit</p>
      </div>

      <div className="mb-4">
        <p >Doctor's Name:</p>
        <p className="text-lg font-semibold">Dr. Kaveri Jain</p>
      </div>

      <div className="mb-4">
        <p >Date & Time:</p>
        <p className="text-lg font-semibold">23/08/2024, <br />2.00 pm - 2.30 pm</p>
      </div>

      <div className="mb-4">
        <p >Total Amount Payable:</p>
        <p className="text-lg font-semibold">₹1000</p>
      </div>

      <button className="w-full bg-[#a44484] text-white py-2 rounded-md hover:bg-[#a44484]">
        Book Appointment
      </button>
    </div>
  );
};

export default AppointmentSummary;
