import React, { useState } from "react";
import { getAxiosWithToken } from "../../axios/AxiosObj";
// import { useDispatch, useSelector } from "react-redux";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../../function/utilies";
import InputError from "../../component/props/InputError";
import { AddButton } from "../../component/props/Buttons";

const initialValues = {
  Title: "",
  Image: "",
  details: "",
  date: "",
  Business_Id: "",
};

const AddEvent = ({ uniqueKey }) => {
  const CreateSchema = Yup.object({
    Title: Yup.string().required("Title is required."),
    Image: Yup.mixed().required("Image is required."),
    details: Yup.string().required("Details are required."),
    date: Yup.date().required("Date is required."),
  });

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [docs, setDocs] = useState(null);

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: initialValues,
      validationSchema: CreateSchema,
      onSubmit: async (values, action) => {
        // e.stopPropagation(); // Stops the event from bubbling up
        setLoading(true);
        let data = new FormData();
        data.append("Title", values.Title);
        data.append("details", values.details);
        data.append("date", values.date);
        data.append("Business_Id", uniqueKey);
        if (docs) {
          data.append("Image", docs);
        }

        try {
          const response = await getAxiosWithToken({
            method: "POST",
            url: "crud/events/",
            data: data,
            reqType: "formData",
          });
          if (response.data) {
            alertResponseMsgSuccess("Successful...");
            action.resetForm();
            setOpen(false);
            setpreview("");
            setDocs("");
          } else {
            alertResponseMsgError(response.data.msg);
          }
        } catch (error) {
          console.error(error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.msg
          ) {
            alertResponseMsgError(error.response.data.msg);
          } else {
            alertResponseMsgError("An error occurred");
          }
        } finally {
          setLoading(false);
        }
      },
    });

  // Function to handle button click and open the confirmation popup
  const handleButtonClick = (e) => {
    setOpen(true);
    e.stopPropagation();
  };

  // Function to handle cancellation of the action
  const handleCancel = (e) => {
    setOpen(false);
    e.stopPropagation();
  };

  //----------------------Doucments ---------------------------------------
  const [preview, setpreview] = useState();
  // const fileInputRef = useRef(null);
  console.log("docs", docs);

  const handleChangeFile = (e) => {
    const { files } = e.target;
    const file = files[0];

    if (file) {
      // Validate file size (limit to 2 MB)
      if (file.size > 2000000) {
        alertResponseMsgError("File size should be less than 2 MB");
        return false;
      }

      // Validate file type (allow only PDF, DOCX, JPG, JPEG, PNG)
      const allowedTypes = [
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "image/jpeg",
        "image/jpg",
        "image/png",
      ];
      if (!allowedTypes.includes(file.type)) {
        alertResponseMsgError(
          "File format should be PDF, DOCX, JPG, JPEG, or PNG"
        );
        return false;
      }

      // If the file is valid, update the state
      setDocs(file);
      setFieldValue("Image", file); // Update Formik state

      // If you want to preview image files (optional)
      if (file.type.startsWith("image/")) {
        const imageUrl = URL.createObjectURL(file);
        // Uncomment the line below to use for image preview
        setpreview(imageUrl);
      }
    }
  };
  return (
    <>
      {/* The Activate/Deactivate Button */}
      <button
        onClick={handleButtonClick}
        className={`px-2 rounded-borderRedius py-1 text-textColor`}
      >
        <EditCalendarIcon />
      </button>

      {/* Confirmation Popup */}
      {open && (
        <div
          className="fixed inset-0 flex items-center justify-center overflow-x-scroll bg-black bg-opacity-50 z-50"
          onClick={handleCancel} // Close the popup when clicking the backdrop
        >
          <div
            className="absolute bg-white p-6 rounded-lg shadow-lg max-w-md w-full top-4"
            onClick={(e) => e.stopPropagation()} // Prevent the popup from closing when clicking inside it
          >
            <div className="bg-white">
              {loading && <div className=""></div>}

              <form onSubmit={handleSubmit} className="">
                {/* Title Field */}
                <div className="mb-6">
                  <label htmlFor="Title" className="form-label">
                    Title
                  </label>
                  <input
                    type="text"
                    id="Title"
                    name="Title"
                    placeholder="Enter title"
                    onChange={handleChange}
                    className="form-input"
                    value={values.Title || ""}
                  />
                  <InputError
                    error={errors.Title}
                    touched={touched.Title}
                    warning={false}
                  />
                </div>

                {/* Detail Field */}
                <div className="mb-6">
                  <label htmlFor="detail" className="form-label">
                    Detail
                  </label>
                  <textarea
                    id="details"
                    name="details"
                    placeholder="Enter detail"
                    onChange={handleChange}
                    className="form-input"
                    value={values.details || ""}
                  />
                  <InputError
                    error={errors.details}
                    touched={touched.details}
                    warning={false}
                  />
                </div>

                {/* Date Field */}
                <div className="mb-6">
                  <label htmlFor="date" className="form-label">
                    Date
                  </label>
                  <input
                    type="date"
                    id="date"
                    name="date"
                    onChange={handleChange}
                    className="form-input"
                    value={values.date || ""}
                  />
                  <InputError
                    error={errors.date}
                    touched={touched.date}
                    warning={false}
                  />
                </div>

                {/* Image Upload Field */}
                <div className="mb-6">
                  <label htmlFor="image" className="form-label">
                    Image
                  </label>
                  <input
                    type="file"
                    id="image"
                    name="image"
                    onChange={handleChangeFile}
                    className="form-input"
                    // value={values.image || ""}
                  />
                  <InputError
                    error={errors.Image}
                    touched={touched.Image}
                    warning={false}
                  />
                </div>

                {/* Image Preview */}
                <div className="flex justify-center bg-gray-100 p-2">
                  {preview && (
                    <img
                      src={preview}
                      alt="Uploaded"
                      className="w-full ring-white bg-white"
                    />
                  )}
                </div>

                {/* Submit Button */}
                <div className="flex justify-between mt-3">
                  <div className="flex justify-end">
                    {loading ? (
                      <AddButton type="button" name="Adding..." />
                    ) : (
                      <AddButton type="submit" name="Submit" />
                    )}
                  </div>
                  <button
                    onClick={handleCancel}
                    type="button"
                    className="px-4 py-2 bg-gray-300 text-gray-700 hover:bg-gray-400 focus:outline-none"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddEvent;
