/* eslint-disable react/prop-types */
// import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
// import RefreshIcon from "@mui/icons-material/Refresh";

import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { useNavigate } from "react-router-dom";


export const AddButton = ({ name, href, type = "button" }) => {
  return (
    <div>
        <button
          type={type} // Set the type correctly
          className="bg-[#a64686] text-white px-4 py-2 cursor-pointer hover:scale-102 active:opacity-85 hover:shadow-soft-xs"
        >
          {name}
        </button>
    </div>
  );
};



export const BackButton = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div
        className="flex items-center gap-4 text-xl font-semibold text-left "
        onClick={() => navigate(-1)}
      >
        <div className="hover:opacity-50 rounded-full pb-1 px-2 text-center">
          <KeyboardBackspaceOutlinedIcon />
        </div>
      </div>
    </div>
  );
};
