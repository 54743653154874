import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { AddButton } from "../../../component/props/Buttons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../../../function/utilies";
// import { useNavigate } from "react-router";
import InputError from "../../../component/props/InputError";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { logoutUser } from "../../../store/feature/userSlice";
import DeleteDialogBox from "../../../component/cards/DeleteDialogBox";
const initialValues = {
  partnerName: "",
};
const UpdatePartner = ({ handleForm, UpdateId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLogged } = useSelector((state) => state.user);
  // const usenavigate = useNavigate();
  const supplierCreateSchema = Yup.object({
    partnerName: Yup.string().required("Partner Name is required."),
  });

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: supplierCreateSchema,
    onSubmit: async (values, action) => {
      let data = new FormData();
      data.append("Partner_Name", values.partnerName);

      try {
        const response = await getAxiosWithToken({
          method: "POST",
          url: `crud/partner/${UpdateId}/`,
          data: data,
        });
        console.log("response.data.success", response.data);
        if (response.data) {
          alertResponseMsgSuccess(response.data.msg);
          handleForm(); // Close form on successful submission
          action.resetForm();
        } else {
          alertResponseMsgError(response.data.msg);
        }
      } catch (error) {
        console.error(error);
        if (error.response && error.response.data && error.response.data.msg) {
          alertResponseMsgError(error.response.data.msg);
        } else {
          alertResponseMsgError("An error occurred");
        }
      }
    },
  });
  //------------------------getPatner----------------------------------------------

  const [allDataRaw, setAllDataRaw] = useState([]);
  console.log("allDataRaw", allDataRaw);
  const getPatner = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: `crud/partner/${UpdateId}/`,
      });

      if (response.data) {
        setAllDataRaw(response.data);
        values.partnerName = response.data.Partner_Name;
        values.Id = response.data.Id;
      } else {
        console.error("Failed to fetch details:", response.data.msg);
      }
    } catch (error) {
      navigate(-1);
      if (!error.response.data.success) {
        navigate(-1);
      }
    }
  };
  useEffect(() => {
    if (isLogged) {
      getPatner();
    }
  }, [isLogged]);

  //-------------------------------------------Delete API-----------------------------------------//
  const [open, setOpen] = useState(false);
  const [deleteModelData, setDeleteModelData] = useState({});

  const handleDeleteModel = (key = "none") => {
    console.log("deleteActive", key);
    if (key !== "") {
      setOpen(!open);
      setDeleteModelData({
        deleteUrl: `crud/partner/${key}/`,
        headerText: `Confirm Deletion`,
        paraText: `Partner?`,
      });
    }
  };

  return (
    <>
      <div className="popup-firstLayer">
        <div className="relative p-4 w-full my-6 mx-auto max-w-2xl">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="text-right">
              <button onClick={handleForm}>
                <CloseOutlinedIcon />
              </button>
            </div>
            <h2 className="text-2xl font-bold mb-6 text-center">
              Update Empaneled Partner
            </h2>

            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="partnerName" className="form-label">
                  Partner Name *
                </label>
                <input
                  type="text"
                  id="partnerName"
                  name="partnerName"
                  placeholder="Enter name"
                  className="popform-input"
                  value={"" || values.partnerName}
                  onChange={handleChange}
                />
                <InputError
                  error={errors.partnerName}
                  touched={touched.partnerName}
                  warning={false}
                />
              </div>

              <div
                // onClick={handleSubmit}
                className="flex gap-4 justify-end mt-6"
              >
                <AddButton type="submit" name="Update" />
                <div
                  className="w-full"
                  onClick={() => handleDeleteModel(values.Id)}
                >
                  <AddButton name="Delete" />
                </div>
              </div>
            </form>
          </div>
        </div>
        <DeleteDialogBox open={open} setOpen={setOpen} data={deleteModelData} />
      </div>
    </>
  );
};

export default UpdatePartner;
